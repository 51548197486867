import { find, findAll } from "../utils/dom";

const Page_Conteudos = () => {
    const page = find("[js-page='conteudos']");
    if (!page) return;

    function removeFiltro(id_pai) {
        const anterior = find(`#${id_pai} .filtro-active`);
        if (anterior) {
            anterior.classList.remove('filtro-active');
        }
    }

    function addFiltro(No) {
        No.classList.add('filtro-active');
    }

		function definirMesPadrao() {
			const mesAnoSelect = find('#mes_ano');
			const mesAnoOptions = mesAnoSelect.querySelectorAll('option');
			const mesAnoRecente = mesAnoOptions[mesAnoOptions.length - 1];
			mesAnoRecente.selected = true;
		}

    const filtros = findAll('[js-filtro]');
    if (filtros) {
        filtros.forEach((select) => {
            select.addEventListener("change", (e) => {
                const id = select.id;
                removeFiltro(id);
                addFiltro(e.target);

                const Mes_Ano = find('#mes_ano');
                const Value_Mes_Ano = Mes_Ano.value;

                const Semana = find('#semana');
                const Value_Semana = Semana.value;

                const URL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

                const NovaURL = `${URL}?mes_ano=${Value_Mes_Ano}&semana=${Value_Semana}`;
                window.location.replace(NovaURL);
            })
        })
    }

    let atual_url = new URL(window.location.href)
    let semana_url = atual_url.searchParams.get("semana");
    let mes_url = atual_url.searchParams.get("mes_ano");

    if (semana_url) {
        const container_atual_semana = find('[js-atual-semana]');
        container_atual_semana.innerText = `${semana_url}`;

        const Semana = find('#semana');
        removeFiltro('semana');
        Semana.value = semana_url;
        const Semanas = findAll('#semana option');
        Semanas[parseInt(semana_url) - 1].classList.add('filtro-active');
    }

    if (mes_url) {
        const Mes_Ano = find('#mes_ano');
        Mes_Ano.value = mes_url
    } else {
			definirMesPadrao();
		}

    function limparFiltro() {


        if (semana_url || mes_url) {
            const limparFiltro = document.querySelector('.limpar-filtro')
            limparFiltro.classList.toggle('text-hidden')

            limparFiltro.addEventListener('click', (e) => {
                console.log(e.target);
                const URL = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
                window.location.replace(URL);
                limparFiltro.classList.toggle('text-hidden')
            })

        }

    }

    limparFiltro()
}

export default Page_Conteudos;