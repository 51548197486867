import { find, findAll } from "../utils/dom";
import Swiper from "swiper"

const Page_Conteudos = () => {
    const page = find("[js-page='fundos']");
    if (!page) return;

    console.log("Fundos");


    const swiperParceirosFundos = new Swiper(".swiper-parceiros-fundos", {
        spaceBetween: 32,
        slidesPerView: 'auto',
        loop: true,
        speed: 2000,

        autoplay: {
            delay: 1,
            disableOnInteraction: false
        }
    });

}

export default Page_Conteudos;